import { Checkbox } from "@icasdigital/icas.core.reactcomponents";
import React, { FC } from "react";

type GiftAidProps = {
	isApplied: boolean;
	handleChange: (id: string, checked: boolean) => void;
};

export const GiftAid: FC<GiftAidProps> = ({ isApplied, handleChange }) => {
	return (
		<>
			<h3>Gift Aid</h3>
			<p>Boost your donation by 25p of Gift Aid for every £1 you donate.</p>
			<Checkbox
				id="isGiftAidApplied"
				label="I would like to add Gift Aid to my donation"
				handleChange={handleChange}
				checked={isApplied}
			/>
			<p>
				By ticking to Gift Aid your donation, you are confirming that the amount
				donated is a personal donation to the ICAS Foundation and/or SCABA.You
				are confirming you are a UK taxpayer and you understand that if you pay
				less Income Tax and/or Capital Gains Tax, than the amount of Gift Aid
				claimed on all your donations in that tax year, it is your
				responsibility to pay any difference.
			</p>
			<p>
				If you pay Income Tax at the higher or additional rate you must include
				all your Gift Aid donations on your Self-Assessment tax return to claim
				further tax relief.
			</p>
			<p>
				If you are unsure whether your donations qualify for gift aid tax
				relief, refer to donations by individuals on the{" "}
				<a href="https://www.hmrc.gov.uk/" target="_blank" rel="noreferrer">HMRC website</a>.
			</p>
			<p>
				Please let us know if your tax circumstances and/or your name and
				address change so that we can update our records.
			</p>
		</>
	);
};
