import { Donation, DonationInfo } from "./Donation";
import { UpdatesFromICASFoundation } from "./UpdatesFromICASFoundation";
import { GiftAid } from "./GiftAid";

export type CharityState = {
	isFoundationContactConsentGiven?: boolean;
	isGiftAidApplied: boolean;
	donationICASFoundation?: DonationInfo;
	donationSCABA?: DonationInfo;
};

type CharityCardProps = {
	charityState: CharityState;
	handleDonationChange: (stateName: string, donation: DonationInfo) => void;
	handleCheckboxChange: (stateName: string, checked: boolean) => void;
};

	const foundationDonationOptions = [
		{ id: 1, amount: 100, labelText: `${100}`, description: "Can help with accomodation" },
		{ id: 2, amount: 50, labelText: `${50}`, description: "Can help with food and travel" },
		{ id: 3, amount: 30, labelText: `${30}`, description: "Can help purchase books" },
		{ id: 4, amount: 0, labelText: `I do not wish to donate`,},
		]

	const scabaDonationOptions = [
		{ id: 1, amount: 100,labelText: `${100}`},
		{ id: 2, amount: 50, labelText: `${50}` },
		{ id: 3, amount: 30, labelText: `${30}` },
		{ id: 4, amount: 0, labelText: `I already donate`, },
		{ id: 5, amount: 0, labelText: `I do not wish to donate`, },
	]
			

export const CharityCard = ({
	charityState,
	handleDonationChange,
	handleCheckboxChange,
}: CharityCardProps) => {
	const haveDonation =
		(charityState.donationICASFoundation?.amount ?? 0) +
			(charityState.donationSCABA?.amount ?? 0) >
		0;

	return (
		<div className="card charity">
			<div className="card__content">
				<h2 className="welcome with-line">Add a charity donation</h2>
				<Donation
					name="donationICASFoundation"
					title="ICAS Foundation"
					subtitle="Help those in disadvantaged communities follow in your footsteps"
					description="Accountancy is a great career. The ICAS Foundation needs your help more than ever to widen access to the profession and give academically talented young people, living in disadvantaged communities, the opportunity to follow in your footsteps. Please give what you can. Thank you."
					website={{
						url: "https://icasfoundation.org.uk/",
						title: "ICAS Foundation website",
					}}
					donationOptions={foundationDonationOptions}
					donation={charityState.donationICASFoundation}
					handleChange={handleDonationChange}
				/>
				<UpdatesFromICASFoundation
					consentGiven={charityState.isFoundationContactConsentGiven}
					handleChange={handleCheckboxChange}
				/>
				<hr />
				<Donation
					name="donationSCABA"
					title="SCABA"
					subtitle="The Scottish Chartered Accountants Benevolent Association"
					description="SCABA is a charity set up to help members of ICAS and/or their dependants who are in need. Ill heath, redundancy, unemployment and death are just some of the life events affecting members and their families with which SCABA has helped."
					website={{
						url: "https://www.icas.com/scaba-the-charity-for-cas-in-need",
						title: "SCABA website",
					}}
					donationOptions={scabaDonationOptions}
					donation={charityState.donationSCABA}
					handleChange={handleDonationChange}
				/>
				<hr />
				{haveDonation && (
					<GiftAid
						isApplied={charityState.isGiftAidApplied}
						handleChange={handleCheckboxChange}
					/>
				)}
			</div>
		</div>
	);
};
