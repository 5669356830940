import { GBPCurrencyInput, Radio } from "@icasdigital/icas.core.reactcomponents";
import { FC, useMemo } from "react";
import { OrderProduct } from "../../../types/Product";

export type DonationInfo = {
	amount?: number;
	alreadyDonate?: boolean;
};

export type DonationButtonInfo = {
	amount: number;
	id: number;
	labelText: string;
	description?: string;
};

// Find value item matching donation
function findSelectedValueByDonation(
	donationButtons: DonationButtonInfo[],
	donation?: DonationInfo
) {
	if (donation?.amount) {
		return donationButtons.find((button) => donation?.amount === button.amount)
			?.id;
	}
	if (donation?.alreadyDonate) {
		return donationButtons.find((button) => donation?.amount === button.amount)
			?.id;
	}
}

// Create donation from numeric value
function createDonationFromValue(
	value: string | number | undefined
): DonationInfo {
	const alreadyDonate = value === undefined;
	return {
		alreadyDonate: alreadyDonate,
		amount: alreadyDonate ? undefined : parseFloat(value.toString()),
	};
}

type DonationProps = {
	name: string;
	title: string;
	subtitle: string;
	description: string;
	website: {
		title: string;
		url: string;
	};
	donationOptions: DonationButtonInfo[];
	donation?: DonationInfo;
	orderLine?: OrderProduct;
	handleChange: (stateName: string, amount: DonationInfo) => void;
};

export const Donation: FC<DonationProps> = ({
	name,
	title,
	subtitle,
	description,
	website,
	donationOptions,
	donation,
	handleChange,
}) => {
	const donationButtons = donationOptions.map((button) => {
		return (
			<Radio.Button
				key={button.id}
				{...button}
				id={`${button.id}-${button.labelText}-option`}
				value={button.amount}
			/>
		);
	});

	// Set the donation amount from selected item
	const handleDonationSelection = (id: string, value: number | string) => {
		const selectedDonation = donationOptions.find(
			(option) => option.amount === parseInt(value.toString())
		);
		if (!selectedDonation) {
			return;
		}
		const donationInfo = createDonationFromValue(selectedDonation.amount);
		handleChange(name, donationInfo);
	};

	// Set the donation amount from entered value
	const handleDonationAmountChange = (value: string | number | undefined) => {
		const changedDonation = createDonationFromValue(value);
		handleChange(name, changedDonation);
	};
	return (
		<>
			<h3>{title}</h3>
			<Radio
				heading={subtitle}
				name={name}
				handleChange={handleDonationSelection}
				selectedValue={donation?.amount}
			>
				<Radio.Description description={description} />
				<Radio.ButtonContainer vertical style={{gap: "8px"}}>{donationButtons}</Radio.ButtonContainer>
			</Radio>
			<GBPCurrencyInput
				id={`${name}__otherAmount`}
				labelText="Other amount"
				value={donation?.amount ?? ""}
				handleChange={handleDonationAmountChange}
			/>
			<p>
				For more information - visit the{" "}
				<a href={website.url} target="_blank" rel="noreferrer">
					{website.title}
				</a>
			</p>
		</>
	);
};
