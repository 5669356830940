import React, { FC } from "react";
import {  Radio } from "@icasdigital/icas.core.reactcomponents";
import { useBooleanRadioButtons } from "../../../hooks/useBooleanRadioButtons";

const radioHeading = "Please let us know it's okay for ICAS Foundation to contact you. We take your privacy seriously and will never sell your data to third parties."

type UpdatesFromICASFoundationProps = {
	consentGiven?: boolean;
	handleChange: (stateName: string, consentGiven: boolean) => void;
};

export const UpdatesFromICASFoundation: FC<UpdatesFromICASFoundationProps> = ({
	consentGiven,
	handleChange,
}) => {
	const { selectedValue, yesValue, noValue, findValue } = useBooleanRadioButtons(consentGiven);

	const handleChangeOfConsent = (name: string, value: string | number) => {
		const key = findValue(name, value);
		if (key === undefined) {
			return;
		}
		handleChange(name, key);
	};

	return (
		<>
			<h3>Updates from ICAS Foundation</h3>
			<p>
				ICAS Foundation would like to contact you with updates on the ways in
				which we are increasing social mobility in the accountancy profession.
				We support talented accountancy and finance students facing significant
				barriers to higher education. With your help we will continue to change
				the lives of those who, given the chance, could very well shape the
				future of ICAS and the profession.
			</p>
			<p>
				Did you know that 96% of the students we help go on to graduate from
				university with a First Class Honours or Upper Second Class degree, and
				that a significant number also progress to the prestigious ICAS CA
				qualification?
			</p>
			<Radio heading={radioHeading} name="isFoundationContactConsentGiven" handleChange={handleChangeOfConsent} selectedValue={selectedValue}>
				<Radio.ButtonContainer vertical style={{gap: "8px"}}>
					<Radio.Button labelText="Yes" id="foundation-consent__true" value={yesValue} />
					<Radio.Button labelText="No" id="foundation-consent__false" value={noValue} />
				</Radio.ButtonContainer>
			</Radio>
		</>
	);
};
